export const accessoryInfo = [
  {
    title: "Alaway",
    subheader: "Immediate allergy and eye-itch relief.",
    description: "The first and only FDA-approved, over-the-counter, preservative free, antihistamine eye drop. ",
    points: ["Blocks the release of histamines and reduces allergic inflammation","Works in minutes","Lasts up to 12 hours"],
    metaTitle: 'Alaway Over-the-Counter Allergy Relief Eye Drops',
    metaDescription: 'Get immediate allergy and eye-itch relief with Alaway: the first and only FDA-approved OTC  antihistamine eye drop from Bausch & Lomb. Alaway works in just minutes and can last up to 12 hours.',
    brand: "Bausch + Lomb"
  },
  {
    title: "Avenova Eye Compress",
    subheader: "A mask to soothe irritated eyes.",
    description: "An easy-to-use eye mask that gives instant relief from Blepharitis, dry eyes, crusty eyelashes, allergies, sinus headaches, and puffy, irritated eyes.",
    points: ["Microwave for just 15-20 seconds and use over closed eyes for 15-20 minutes","Soft outer covering for a comfortable fit","Handwash and reuse over and over again"],
    metaTitle: 'Avenova Eye Mask and Compress for Irritated Eyes',
    metaDescription: 'The Avenova eye compress can provide instant relief from Blepharitis, dry eyes, irritation and more. With a soft out covering, the Avenova eye compress can be handwashed and reused over and over again.',
    brand: "NovaBay"
  },
  {
    title: "Avenova OTC Antimicrobial Spray Solution",
    subheader: "Spray for relief from irritated, dry eyes.",
    description: "The only lab-tested, FDA-cleared pure Hypochlorous Acid formula that helps your body naturally fight off toxins and inflammation caused by bacteria.",
    points: ["Previously available by prescription only","Safe for daily use","No harmful impurities like bleach, detergents or other surfactants"],
    metaTitle: 'Avenova OTC Antimicrobial Spray Solution for Dry Eyes',
    metaDescription: 'Get the best eye care accessories from Hubble. This OTC spray is FDA-cleared to provide relief for irritated and dry eyes caused by bacteria and toxins. Avenova spray is safe for daily use.',
    brand: "NovaBay"
  },
  {
    title: "Biotrue Hydration Boost Eye Drops",
    subheader: "Drops that instantly rehydrate your eyes.",
    description: "#1 Eye Doctor Recommended Give your eyes the instant boost they need with drops designed to help relieve dryness.",
    points: ["Delivers instant moisture and hydration","Preservative-free and pH balanced","Can be used with or without contact lenses"],
    metaTitle: 'Biotrue Hydration Boost Rehydrating Eye Drops',
    metaDescription: 'Give your eyes an instant boost with Biotrue Hydration eye drops for irritated, dry eyes.  These preservative-free drops can be used with or without Hubble contacts.',
    brand: "Bausch + Lomb",
    soldOut: false,
  },
  {
    title: "Biotrue Hydration Plus Contact Lens Solution",
    subheader: "A contact cleaning solution that also adds moisture.",
    description: "#1 Eye Doctor Recommended This contact solution does it all—cleans, disinfects, and boosts the moisture in your contacts.",
    points: ["Up to 20 hours of added moisture for all-day comfort" ,"enhanced with an electrolyte, an antioxidant, and 25% more hyaluronan (HA)","pH balanced."],
    metaTitle: 'Biotrue Hydration Plus Contact Lens Solution',
    metaDescription: 'Biotrue Hydraption Plus is the all-in-one contact lens solution. This #1 eye doctor recommended product cleans, disinfects and boosts the moisture in your contacts for all-day comfort.',
    brand: "Bausch + Lomb"
  },
  {
    title: "Bruder Moist Heat Sinus & Allergy Compress",
    subheader: "Wrap compress to relieve sinus pressure.",
    description: "Specially contoured to fit your face, simply heat up to relieve sinus pressure, tension headaches, migraines, TMJ, dry eye syndrome, fatigue, and stress.",
    points: ["Microwavable reusable wrap delivers penetrating moist heat","Antibacterial, anti-fungal, and odor free","Use from the freezer for soothing cold"],
    metaTitle: 'Bruder Moist Heat Sinus & Allergy Compress for Sinus Relief',
    metaDescription: 'Simplify your eye care regime with the contact lens compress from Bruder. Relieve sinus pressure and tension headaches with this microwavable, reusable wrap or use from the freezer for soothing cold.',
    brand: "Bruder"
  },
  {
    title: "Classic Lens Care Kits",
    subheader: "Alcohol-free lens cleaner.",
    description: "Approved for use on all eyewear lens coatings, providing effective, streak-free cleaning.",
    points: ["The Classic Lens Care Kits include a 2 oz, (59 ml) pump spray lens cleaner and a 5.25 x 6.5 (13.3cm x 16.5cm) Classic microfiber cloth in a re-usable vinyl pouch"],
    metaTitle: 'Classic Lens Care Kit for Eyeglasses',
    metaDescription: 'Keep your vision in check with eye care accessories from Hubble. Our Classic Lens Care Kits provide everything you need to keep your lenses clean and scratch-free.',
    brand: "Hilco"
  },
  {
    title: "Lumify Drops",
    subheader: "Reduces redness for white, brighter eyes.",
    description: "#1 Eye Doctor Recommended Redness Reliever A breakthrough, clinically-tested formula that uses low-dose brimonidine tartrate to relieve redness",
    points: ["Reduces risk of rebound redness","Uses no bleach or dyes","Lasts up to 8 hours"],
    metaTitle: 'Lumify Redness Reliever Eye Drops',
    metaDescription: 'Gain white, brighter eyes and relieve redness with Lumify eye drops. These brimonidine tartrate drops use a clinically tested formula to reduce risks of rebound redness for up to eight hours.',
    brand: "Bausch + Lomb"
  },
  {
    title: "NovaWipes",
    subheader: "Soft wipes to help you apply Avenova.",
    description: "Super soft and hypoallergenic wipes specifically designed for use when applying Avenova to your eyelids.",
    points: ["Multi-layer for maximum absorption","Won't pull apart like cotton","Larger than cotton rounds or cotton balls"],
    metaTitle: 'NovaWipes for Applying Avenova Eyelid Spray',
    metaDescription: "Novawipes are specially optimized for use when applying Avenova eyelid spray. These super soft and hypoallergenic wipes are multi-layer for maximum absorption and won't pull apart like cotton.",
    brand: "NovaBay"
  },
  {
    title: "Ocuvite Blue Light",
    subheader: "Helps eyes shield and filter blue light.",
    description: "#1 Eye Doctor Recommended A vitamin supplement to help protect your eyes from the blue light associated with digital devices—from the inside out.",
    points: ["30 soft gels","Formulated with Lutein (25mg) and Zeaxanthin (5mg)","Take one every morning"],
    metaTitle: 'Ocuvite Blue Light Vitamin A Supplement',
    metaDescription: "Protect your eyes from digital device blue light with Ocuvite's Blue Light A vitamin supplement. Formulated with Lutein and Zeaxanthin, protect your eyes with these daily soft gels.",
    brand: "Bausch + Lomb"
  },
  {
    title: "Ocuvite Eye Health",
    subheader: "Help nourish your eyes",
    description: "#1 Eye Doctor Recommended An optimal blend of nutrients helps you to protect your eye health from the inside out.",
    points: ["30 soft gels","Formulated with Lutein, Zeaxanthin, Omega-3 and antioxidants","Take one every morning"],
    metaTitle: 'Ocuvite Daily Eye Vitamin and Mineral Supplement',
    metaDescription: 'Shop Ocuvite daily eye health supplements. Nourish your eyes with an optimal blend of nutrients, including Lutein, Zeaxanthin, Omega-3 and antioxidants in a pack of 30 soft gels.',
    brand: "Bausch + Lomb"
  },
  {
    title: "OptiPlus Anti-Fog Lens Wipes",
    subheader: "Keep your glasses clean, clear, and fog-free.",
    description: "These disposable, single-use wipe are a combo lens cleaner and anti-fog treatment—all in one! ",
    points: ["Disposable single towelette application","Works on all lenses—pecifically anti-reflective coated lenses","3rd party tested and awarded COLTS Performance Seal"],
    metaTitle: 'OptiPlus Anti-Fog Lens Wipes for Eyeglasses',
    metaDescription: 'Protect and clean your lenses with OptiPlus Anti-Fog Lens Wipes. These disposable towelettes work on all eyeglass lenses, including anti-reflective coated lenses from Hubble.',
    brand: "Hilco"
  },
  {
    title: "OptiPlus Anti-Fog Microfiber Cloth",
    subheader: "Reduce and prevent fog build up on your glasses.",
    description: "Just one swipe applies anti-fog treatment to your lenses significantly reducing the fogging of your glasses.",
    points: ["Easy to use and safe for all eyewear","One application lasts all day","Reusable up to 100 times"],
    metaTitle: 'OptiPlus Anti-Fog Microfiber Cloth for Eyeglasses',
    metaDescription: 'Reduce glasses fogging with the Anti-Fog Microfiber Cloth from OptiPlus. Not only does one application last all day, but this powerful cloth is also reusable up to 100 times.',
    brand: "Hilco"
  },
  {
    title: "OptiPlus Ultra Soft Lens Wipes",
    subheader: "Super soft lens wipes.",
    description: "Single use wipes as soft as microfiber, that hold moisture, and won't scratch your lenses.",
    points: ["Exclusive formula for streak-free cleaning","Great for on-the-go","100 wipes per box"],
    metaTitle: 'OptiPlus Ultra Soft Lens Wipes for Eyeglasses',
    metaDescription: 'Gently and effectively clean your lenses with OptiPlus Ultra Soft lens wipes. Get everything you need to take care of your eyewear with hand-selected accessories from Hubble.',
    brand: "Hilco"
  },
  {
    title: "Pataday",
    subheader: "Eye allergy itch-relief in minutes.",
    description: "#1 Eye Doctor Recommended Temporarily relieves itchy eyes due to pollen, ragweed, grass, animal hair and dander.",
    points: ["One drop a day","Works in minutes","Provides long-lasting relief"],
    metaTitle: '',
    metaDescription: '',
    brand: "Bausch + Lomb"
  },
  {
    title: "Soothe XP Eye Drops 0.5 FL",
    subheader: "Drops that moisturize and restore your eye.",
    description: "Help is on the way with drops designed to repair your eye’s outer protective lipid layer and lock moisture in.",
    points: ["Contains Restoryl mineral oils","Protects against tear loss","Restores the lipid layer of tears"],
    metaTitle: 'Soothe XP Lubricating Eye Drops',
    metaDescription: 'Protect and relieve your eyes with Soothe XP Lubricating eye drops. These powerful drops moisturizer for fast relief and protects against tear loss by restoring the lipid layer of tears.',
    brand: "Bausch + Lomb"
  },
  {
    title: "Lastacaft Once Daily",
    subheader: "Once daily drop that lasts up to 24hrs.",
    description: "A patented, prescription-strength formula available without the prescription that relieves itchy eyes from allergies in as little as 3 minutes.",
    points: [
      "Provides relief from common irritants like pet dander, grass, pollen and ragweed",
      " Approved for use in adults and children ages 2 and older",
      "Works fast against both seasonal and year round allergens"
    ],
    metaTitle: 'Lastacaft Once Daily',
    metaDescription: 'A patented, prescription-strength formula available without the prescription that relieves itchy eyes from allergies in as little as 3 minutes.',
    brand: "Lastacaft"
  },
  {
    title: "Refresh Optive® MEGA-3 Lubricant Eye Drops",
    subheader: "Designed to relieve moderate symptoms of eye dryness.",
    description: "One-of-a-kind, moisture-rich formula supports all three tear film layers and helps prevent tears from evaporating.",
    points: [
      "The only eye drop in the U.S. with Carboxymethylcellulose sodium, Glycerin, and Flaxseed Oil (an inactive ingredient)",
      "Hydrocell technology enables hydration and maintains the volume of cells on the eye's surface",
      "Great for sensitive eyes and contains 30 single-use, on-the-go vials"
    ],
    metaTitle: 'Refresh Optive® MEGA-3 Lubricant Eye Drops',
    metaDescription: 'One-of-a-kind, moisture-rich formula supports all three tear film layers and helps prevent tears from evaporating.',
    brand: "Refresh"
  },
  {
    title: "Refresh P.M.® Lubricant Eye Ointment",
    subheader: "Nighttime relief for intense eye dryness.",
    description: "REFRESH PM Lubricant Eye Ointment provides nighttime relief from intense burning, irritation, and discomfort due to eye dryness.",
    points: [
      "Preservative-free",
      "Soothes eyes while you sleep",
      "Protects eyes from nighttime dryness due to exposure"
    ],
    metaTitle: 'Refresh P.M.® Lubricant Eye Ointment',
    metaDescription: 'REFRESH PM Lubricant Eye Ointment provides nighttime relief from intense burning, irritation, and discomfort due to eye dryness.',
    brand: "Refresh",
    soldOut: true
  },
  {
    title: "Refresh® DIGITAL PF PRESERVATIVE-FREE",
    altTitle: "Refresh® Digital PF Preservative-Free",
    subheader: "Fast acting relief that soothes your eyes.",
    description: "Preservative-free formula acts fast to relieve dryness, burning, irritation, and discomfort in the eyes that may be due to digital device use.",
    points: [
      "Delivers soothing hydration by supporting all three tear film layers",
      "Helps prevent tears from evaporating due to lipid layer deficiencies",
      "Hydrocell technology enables hydration and maintains the volume of cells on the eye's surface"
    ],
    metaTitle: 'Refresh® Digital PF PRESERVATIVE-FREE',
    metaDescription: 'Preservative-free formula acts fast to relieve dryness, burning, irritation, and discomfort in the eyes that may be due to digital device use.',
    brand: "Refresh"
  },
  {
    title: "Refresh® RELIEVA FOR CONTACTS Lubricant Eye Drops",
    altTitle: "Refresh® Relieva For Contacts Lubricant Eye Drops",
    subheader: "For use with soft and rigid gas permeable (RGP) contact lenses.",
    description: "An innovative lubricant eye drop specially designed for contact lens wearers. It relieves mild to moderate symptoms of eye dryness, irritation, and discomfort associated with contact lens wear.",
    points: [
      "Hydrocell technology enables hydration and maintains the volume of cells on the eye's surface",
      "May also be used to lubricate and rewet soft and rigid gas permeable (RGP) contact lenses and to cushion lenses prior to application",
      "You may keep your contacts in upon application"
    ],
    metaTitle: 'Refresh® Relieva For Contacts Lubricant Eye Drops',
    metaDescription: 'An innovative lubricant eye drop specially designed for contact lens wearers. It relieves mild to moderate symptoms of eye dryness, irritation, and discomfort associated with contact lens wear.',
    brand: "Refresh"
  },
  {
    title: "Refresh® RELIEVA PF PRESERVATIVE-FREE",
    altTitle: "Refresh® Relieva PF Preservative-Free",
    subheader: "There's relief, and then there's RELIEVA for dry, irritated eyes.",
    description: "An innovative preservative-free drop specially formulated not only to relieve discomfort due to dry, irritated eyes, but also prevent further irritation.",
    points: [
      "Hydrocell technology enables hydration and maintains the volume of cells on the eye's surface",
      "On-the-go single use vials and can be used after LASIK or other eye surgeries.",
      "Recommended for sensitive eyes"
    ],
    metaTitle: 'Refresh® Relieva PF Preservative-Free',
    metaDescription: 'An innovative preservative-free drop specially formulated not only to relieve discomfort due to dry, irritated eyes, but also prevent further irritation.',
    brand: "Refresh"
  },
  {
    title: "Refresh® RELIEVA PRESERVATIVE-FREE",
    altTitle: "Refresh® Relieva Preservative-Free",
    subheader: "Innovative, preservative-free formula in a multidose bottle.",
    description: "Eye drops specially formulated not only to relieve discomfort due to dry, irritated eyes, but also prevent further irritation.",
    points: [
      "Hydrocell technology enables hydration and maintains the volume of cells on the eye's surface",
      "Patented multidose bottle with a double locked system keeps drops sterile",
      "Recommended for sensitive eyes"
    ],
    metaTitle: 'Refresh® Relieva Preservative-Free',
    metaDescription: 'Eye drops specially formulated not only to relieve discomfort due to dry, irritated eyes, but also prevent further irritation.',
    brand: "Refresh"
  },
]
