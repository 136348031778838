import { navigate } from 'gatsby';
import React from 'react';
import './style.scss';
import WebpImage from '../WebpImage';

const Banner = (props) => {
  const isHomePageV2 = !!props?.bannerClass?.includes('homepage-v2-banner')

  return (
    <div className={`${props.bannerClass || 'discount-banner'} ${props.bannerBG}`}>
      <button onClick={() => navigate(props.buttonLink)} disabled={!props.clickable}>
        {isHomePageV2
        ? <p className={`text ${props.color}`}>
          <span className='text-mobile'>{props?.bannerText?.mobile}</span>
          <span className='text-desktop' >{props?.bannerText?.desktop}</span>
          <WebpImage fileName="ArrowRightWhite.svg" alt="Arrow right" className='cta-arrow'/>
        </p>
        : <p className={`text ${props.color}`} dangerouslySetInnerHTML={{__html: props.bannerText}} />
        }
      </button>
    </div>
  )
}

export default Banner