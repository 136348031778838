import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { Link, navigate } from "gatsby"

import "./style.scss"
import CartContext from "../../context/cartContext"
import {AccessoryCard, AvgCard, ContactsClassicCard, ContactsHydroCard, ProductCard} from "../ProductWrapper"
import { recommendsList } from "./mockedData"
import RecommendsList from "../RecommendsList"
import NewButton from "../NewButton"
import CustomOffCanvas from "../CustomOffCanvas"
import { StaticImage } from "gatsby-plugin-image"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart'

const YourCart = ({ show, setShow }) => {
  const { cartItems, subtotal, cartItemsQuantity } = useContext(CartContext)
  const [recommendedItemsList, setRecommendedItemsList] = useState(recommendsList)
  const ga_items = []
  const isLifemart = useIsLifemart()
  const hasContactsAvg = cartItems?.some(e => e.type === "contacts" && e.isAvg)

  const discountedSubtotal = cartItems?.reduce((subtotal, { price, sale_price, isOneEye, quantity, type, glassesBasePrice, sunglassesBasePrice, lensType, material, prescriptionType }) => {
    let discountedPrice = (sale_price || price)
    const framesBasePrice = type === "glasses" ? glassesBasePrice : type === "sunglasses" ? sunglassesBasePrice : 0

    if (isLifemart && (type === "glasses" || type === "sunglasses")) {
      const addOnsPrice = lensType?.price + material?.price + prescriptionType?.price
      discountedPrice = (framesBasePrice + addOnsPrice) * 0.75
    }
    
    subtotal += discountedPrice * quantity * (type === "contacts" && !isOneEye && Number(price) !== 1 ? 2 : 1)
    return subtotal
  }, 0)

  useEffect(() => {
    const newRecommendsList = recommendsList.filter((item) => !cartItems.some((el) => el.product_id === item.product_id))
    setRecommendedItemsList(newRecommendsList)
  }, [cartItems])


  const mapGAItems = () => {
    cartItems.map( (a) =>{
      let item_category = a.type
      let item_category2 = a.type

      // item category logic
      if (item_category === "glasses" || item_category === "sunglasses"){
        item_category = "frames"
      }
      if (item_category === "accessory"){
        item_category = "accessories"
      }
      /// item category 2 logic
      if (item_category2 === "glasses") {
        item_category2 = "optical"
      }
      if (item_category2 === "contacts") {
        item_category2 = "spherical"
      }

      if (item_category2 === "accessory"){
        item_category2 = "contact accessories"
      }


      ga_items.push({
        index: 1,
        item_id: a.product_id || a.shopifyProductId || "2167100637257",
        item_name: a.title,
        item_list_id: 'N/A',
        item_list_name: 'N/A',
        currency: "USD",
        discount: 0,
        item_brand: "Hubble",
        item_category: item_category,
        item_category2: item_category2,
        item_category3: 'N/A',
        item_category4: 'N/A',
        item_category5: 'N/A',
        item_variant:  a.variant?.title || a.title,
        price: parseFloat(a.price),
        quantity: 1
      })
    })
  }

  mapGAItems()

  useEffect(() => {
    if ( show === true && typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null })

      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          items: ga_items
        }
      })
    }
  }, [show])

  const hide = () => setShow(false)

  const handleClose = () => {
    document.querySelector('.custom-offcanvas').setAttribute("style", "transform: translate(100%, 0);")
    setTimeout(hide, 700)
  }

  const handleCheckout = () => {
    document.querySelector('.custom-offcanvas').setAttribute("style", "transform: translate(100%, 0);")
    setTimeout(hide, 700)
  }

  const closeAndNavigate = (link) => {
    if (show) {
      handleClose()
      setTimeout(() => {navigate(link)}, 700)
    }
  }

  return (
    <CustomOffCanvas
      show={show}
      onHide={handleClose}
      className="your-cart"
      empty={!cartItems.length}
    >
      <CustomOffCanvas.Header onHide={handleClose}>
        <h5 className="text h5 deep-blue">Your Cart</h5>
        <span className="text h10 deep-blue">{cartItemsQuantity} item(s)</span>
      </CustomOffCanvas.Header>
      <CustomOffCanvas.Body>
        {!!cartItems.length ? (
          <>
            <ProductsContainer cartItems={cartItems} />
           {(recommendedItemsList.length !== 0) && <div className="recommends-container">
              <h6 className="title text h6 deep-blue">Recommended for you</h6>
              <RecommendsList recommendsList={recommendedItemsList} />
            </div>}
          </>
        ) : (
          <EmptyCart navigateTo={closeAndNavigate} />
        )}
      </CustomOffCanvas.Body>
      {!!cartItems.length && (
        <CustomOffCanvas.Footer>
          <div className="subtotal">
            <span className="text h7 deep-blue">Subtotal</span>
            <span className="text h7 neue-bold deep-blue">${isLifemart ? parseFloat(discountedSubtotal)?.toFixed(2) : subtotal}</span>
          </div>
          <NewButton label="Checkout" to="/checkout" onClick={handleCheckout} />
        </CustomOffCanvas.Footer>
      )}
    </CustomOffCanvas>
  )
}

const ProductsContainer = ({ cartItems }) => { 
  const isLifemart = useIsLifemart()
  const hasContactsAvg = cartItems?.some(e => e.type === "contacts" && e.isAvg)

  return (
  <div className="products-container">
    {React.Children.toArray(
      cartItems.map(item => {
        const { type, isHydro, isSkyhy, prescription } = item
        const filteredPrescriptionObj = prescription ? Object.keys(prescription).reduce((acc, key) => {
          if (Object.values(prescription[key]).length !== 0) {
            acc[key] = prescription[key]
          }
          return acc
        }, {}) : {}

        if (type === "accessory") return <AccessoryCard {...item} isLifemart={isLifemart} hasContactsAvg={hasContactsAvg} />

        if (item.isAvg) return <AvgCard {...item} filteredPrescriptionObj={filteredPrescriptionObj} />

        if (type === "contacts" && !(isHydro || isSkyhy)) return <ContactsClassicCard {...item} filteredPrescriptionObj={filteredPrescriptionObj} />

        if (type === "contacts" && (isHydro || isSkyhy)) return <ContactsHydroCard {...item} filteredPrescriptionObj={filteredPrescriptionObj} />

        return <ProductCard {...item} isLifemart={isLifemart}/>
      })
    )}
  </div>
)}

const EmptyCart = ({ navigateTo }) => (
  <>
    <div className="shop-links-container">
      <p className="text h7">Your cart is empty but it doesn’t have to be...</p>
      <div className="shop-links">
        <NewButton type='button' label="Shop Contacts" onClick={() => navigateTo('/contact-lenses/hubble')} />
        <NewButton type='button' label="Shop Glasses" onClick={() => navigateTo('/glasses')} />
        <NewButton type='button' label="Shop Sunglasses" onClick={() => navigateTo('/sunglasses')} />
        <NewButton type='button' label="Shop Accessories" onClick={() => navigateTo('/eye-care-accessories')} />
      </div>
    </div>
    <div className="contacts-promo" data-color="purple">
      <div className="text-block">
        <p className="question text h11 neue-bold uppercase">
          In need of new contacts?
        </p>
        <p className="answer text h7 neutral">We got you covered!</p>
        <Link to={'/contact-lenses/hubble'} className="to-shop" onClick={() => navigateTo('/contact-lenses/hubble')}>
          <span className="text h8 bulky">Shop Contact Lenses</span>
          <FontAwesomeIcon icon={faArrowRight} size="sm" />
        </Link>
      </div>
      <StaticImage
        src="../../images/Cart/Hubble-Contacts-Mini-Cart-Promo.jpg"
      />
    </div>
  </>
)

export default YourCart
